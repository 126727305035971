/* body {
	align-items: center;
	background: #E3E3E3;
	display: flex;
	height: 100vh;
	justify-content: center;
} */

/* @mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
} */

/* $animationSpeed: 40s; */

@keyframes scroll {
	0% { 
		transform: translateX(0); 
	}
	100% { 
		transform: translateX(calc(-140px * 9));
	}
}


/* // Styling */
.slider {
	background: transparent;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
	height: 120px;
	/* margin: auto; */
	overflow:hidden;
	position: relative;
	width: 760px;
	
	/* ::before,
	::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	::before {
		left: 0;
		top: 0;
	} */
	
}

.slide-track {
	animation: scroll 13s linear infinite;
	display: flex;
	width: calc(140px * 18);
}

.slide {
	height: 120px;
	width: 120px;
	margin-left: 10px;
	margin-right: 10px;
	border-radius: 15px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.slide > img {
	height: 80px;
	width: 80px;
}
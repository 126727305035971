.footer {
    padding-top: 120px;
    /* padding-bottom: 120px; */
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    flex-direction: row;
}

.footer2 {
    padding-top: 120px;
    /* padding-bottom: 120px; */
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    flex-direction: row;
}

.left-footer {
    width: 50%;
}

.right-footer {
    width: 50%;
    display: flex;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}

.left-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.right-column {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.header {
    font-size: 16px;
    font-family: SFProMedium;
    color: white;
    letter-spacing: 4px;
}

.left-column > a {
    color: white;
    font-family: SFProDisReg;
    /* padding: 2px; */
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 14px;
    text-decoration: none;
}

.right-column > a {
    color: white;
    font-family: SFProDisReg;
    /* padding: 2px; */
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 14px;
    text-decoration: none;
}

.underline-on-hover:hover {
    text-decoration: underline;
}

.text {
    color: white;
    font-family: SFProDisReg;
    /* padding: 2px; */
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 14px;
}

.left-footer > p {
    color: white;
    font-family: SFProDisReg;
}

.social-media > img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    /* margin-top: 50px;
    margin-right: 8px; */
}

@media screen and ( max-width: 640px) {
    .footer {
        flex-direction: column;
        padding-top: 60px;
    }

    .left-footer {
        width: 100%;
    }

    .right-footer {
        width: 100%;
    }
}
.timeline {
    position: relative;
    width: 760px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 1em 0;
    list-style-type: none;
  }
  
  .timeline:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 10px;
    height: 100%;
    margin-left: -5px;
    background: rgba(221, 64, 136, 0.1);
    /* background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
    background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%); */
    
    z-index: 5;
  }
  
  .timeline li {
    padding: 1em 0;
  }
  
  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  
  .direction-l {
    position: relative;
    width: 380px;
    float: left;
    text-align: right;
    border-top: 1px solid #DD4088;
  }
  
  .direction-r {
    position: relative;
    width: 380px;
    float: right;
    border-top: 1px solid #DD4088;
  }
  
  .flag-wrapper {
    position: relative;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }
  
  .flag {
    position: relative;
    display: inline;
    /* background: rgb(248,248,248); */
    padding: 6px 10px;
    border-radius: 5px;
    
    font-weight: 600;
    text-align: left;
    font-size: 30px;
    font-family: 'SFProBold';
  }
  
  /* .direction-l .flag {
    -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }
  
  .direction-r .flag {
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  } */
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 50%;
    right: -50px;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -48px;
    background: #DD4088 ;
    border-radius: 10px;
    border: 4px solid rgba(219,70,140);
    z-index: 10;
  }
  
  .direction-r .flag:before {
    left: -50px;
  }
  
  .direction-l .flag:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }
  
  .direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }
  
  .direction-r .time {
    margin-left: 50px;
    font-family: 'SFProMedium';
    font-size: 16px; 
    margin-top: 4px;
  }

  .direction-l .time {
    margin-right: 50px;
    font-family: 'SFProMedium';
    font-size: 16px; 
    margin-top: 4px;
  }
  
  .time-wrapper {
    display: inline;
    
    line-height: 1em;
    font-size: 0.66666em;
    /* color: rgb(250,80,80); */
    font-family: 'SFProMedium';
    vertical-align: middle;
  }
  
  .direction-l .time-wrapper {
    float: left;
  }
  
  .direction-r .time-wrapper {
    float: right;
  }
  
  /* .time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248,248,248);
  } */
  
  .desc {
    margin: 1em 0.75em 0 0;
    /* margin-left: 50px; */
    
    font-size: 0.77777em;
    /* font-style: italic; */
    line-height: 1.5em;
  }

  .desc > li {
    font-family: 'SFProDisReg';
    font-size: 16px;
    padding: 3px 0px 3px 0px;
  }
  
  .direction-r .desc {
    margin: 1em 0 0 0.75em;
    margin-left: 50px;
  }

  .direction-l .desc {
    margin: 1em 0 0 0.75em;
    margin-right: 50px;
  }
  
  /* ================ Timeline Media Queries ================ */
  
  @media screen and (max-width: 760px) {

    .direction-l .time {
      margin-right: 0px;
      font-family: 'SFProMedium';
      font-size: 16px; 
      margin-top: 4px;
    }

    .direction-r .time {
      margin-left: 0px;
      font-family: 'SFProMedium';
      font-size: 16px; 
      margin-top: 4px;
    }
  
  .timeline {
       width: 100%;
      padding: 4em 0 1em 0;
  }
  
  .timeline li {
      padding: 0.25em 0;
  }
  
  .direction-l,
  .direction-r {
      float: none;
      width: 100%;
  
      text-align: center;
      border-top: 0px;
      margin-top: 40px;
  }
  
  .flag-wrapper {
      text-align: center;
  }
  
  .flag {
      /* background: rgb(255,255,255); */
      background-color: white;
      z-index: 15;
  }
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -0px;
      left: 50%;
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: -10px;
      background: #fff;
      border-radius: 10px;
      border: 4px solid rgba(219,70,140);
      z-index: 10;
  }
  
  .direction-l .flag:after,
  .direction-r .flag:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      height: 0;
      width: 0;
      margin-left: -8px;
      border: solid transparent;
      border-top-color: rgba(219,70,140, 0.5);
      border-width: 8px;
      pointer-events: none;
  }
  
  .time-wrapper {
      display: block;
      position: relative;
      margin: 4px 0 0 0;
      z-index: 14;
  }
  
  .direction-l .time-wrapper {
      float: none;
  }
  
  .direction-r .time-wrapper {
      float: none;
  }
  
  .desc {
      position: relative;
      margin: 1em 0 0 0;
      padding: 1em;
      /* background: rgb(245,245,245); */
      background: #FEF9FB;
      border-radius: 25px;
      /* -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
      -moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
      box-shadow: 0 0 1px rgba(0,0,0,0.20); */
      
    z-index: 15;
  }
  
  .direction-l .desc,
  .direction-r .desc {
      position: relative;
      margin: 1em 1em 0 1em;
      padding: 1em;
      
    z-index: 15;
  }
  
  }
  
  @media screen and (min-width: 400px ?? max-width: 760px) {
  
  .direction-l .desc,
  .direction-r .desc {
      margin: 1em 4em 0 4em;
  }
  
  }
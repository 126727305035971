@font-face {
  font-family: 'SFProDisReg';
  src: local('SFProDisReg'), url(./fonts/SFProDisplay-Regular.woff) format('woff');
                            speak: none;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'SFProBold';
  src: local('SFproBold'), url(./fonts/SFProDisplay-Bold.woff) format('woff');
  /* font-display: optional; */
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  speak: none;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
}


@font-face {
  font-family: 'SFProMedium';
  src: local('SFProMedium'), url(./fonts/SFProText-Medium.woff) format('woff');
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}


html {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -9999;
  /* background: black; */
}

.back {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10000;
  background-color: black;
  /* background: radial-gradient(circle at center top, #a63aeb, black); */
}

  /* .back::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  } */

.container-img {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  left: 0;
  z-index: -10;
  /* background: radial-gradient(50% 50% at 50% 50%, rgba(121, 87, 191, 0.5) 0%, rgba(0, 0, 0, 0.0) 100%); */
  background: radial-gradient(50% 50% at 50% 50%, rgba(124, 41, 111, 0.65) 0%, rgba(0, 0, 0, 0.0) 100%);
}

.img-blur {
  /* position: absolute; */
  /* background-image: url("./assets/backgroun_blur2.png"); */
  /* top: 0; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  width: 100vw;
  height: 100vw;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-position: center;
  transform: translateY(-55%); */
}

.blur-back {
  width: 100%;
  height: 100%;
  /* background: radial-gradient(circle at center top, #a63aeb, black); */
  /* background: url('assets/background_layer.png');
  background-size: contain; */
  background-color: black;
  background: radial-gradient(80.4% 80.4% at 50% 10%, #9370DB 0%, rgba(238, 40, 138, 0) 50%);
  filter: blur(1000px);
}

.back-bluring {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  /* background: radial-gradient(50% 50% at 50% 50%, rgba(210, 71, 139, 0.25) 0%, rgba(0, 0, 0, 0.5) 100%); */
  /* backdrop-filter: blur(15px); */
}

.head {
  width: 100%;
  height: 120px;
  /* background-color: rgba(255, 255, 255, 0.03); */
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  margin-left: 60px;
  height: 60px;
}

.toolbar {
  display: flex;
  margin-right: 60px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "SFProMedium";
  gap: 50px;
  margin-right: 200px;
}

.bottomBlock {
  margin-top: 80px;
  width: 100%;
  /* height: 300px; */
  /* background-color: rgba(255, 255, 255, 0.03); */
}

.button3 {
  background-color: rgba(34, 29, 52, 0.45);
  /* border: none; */
  color: white;
  padding: 18px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'SFProBold';
  border-radius: 20px;
  border: 0.08rem solid;
  border-color:white;
}

.button {
  background-color: rgba(34, 29, 52, 0.85);
  /* border: none; */
  color: white;
  padding: 18px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'SFProBold';
  border-radius: 20px;
  /* border: 0.08rem solid;
  border-color:white; */
  border: 0.08rem solid;
  border-color:rgba(34, 29, 52, 0.85);
}

.button2 {
  /* background-color: rgba(34, 29, 52, 0.85); */
  background-color: black;
  /* border: none; */
  color: white;
  padding: 18px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'SFProBold';
  border-radius: 20px;
  /* border: 0.08rem solid;
  border-color:white; */
}

@keyframes buttonAnim {
  from {background-color: rgba(34, 29, 52, 0.85);}
  to {background-color: rgba(69, 49, 108, 1);}
}

@keyframes buttonAnim2 {
  from {background-color: rgba(69, 49, 108, 1) ;}
  to {background-color: rgba(34, 29, 52, 0.85);}
}

@keyframes button2Anim {
  from {background-color: black;}
  to {background-color: #DD4088;}
}

@keyframes button2Anim2 {
  from {background-color: #DD4088;}
  to {background-color: black;}
}

@keyframes button3Anim {
  from {background-color: rgba(34, 29, 52, 0.45);}
  to {background-color: rgba(34, 29, 52, 0.85);}
}

@keyframes button3Anim3 {
  from {background-color: rgba(34, 29, 52, 0.85);}
  to {background-color: rgba(34, 29, 52, 0.45);}
}

.headerNav {
  color: white;
  cursor: pointer;
}

.headerNav:hover {
  color: #EE288A;
}

.button:hover {
  /* background-color: rgba(189, 167, 234, 1); */
  /* background-color: rgba(121, 94, 175, 1); */

  /* background-color: rgba(69, 49, 108, 1); */
  animation-name: buttonAnim;
  animation-duration: 0.2s;
  background-color: rgba(69, 49, 108, 1);
  /* color: rgba(255, 0, 126, 1); */
}

.button:not(:hover) {
  animation-name: buttonAnim2;
  animation-duration: 1.2s;
  background-color: rgba(34, 29, 52, 0.85);
}

.button2:hover {
  /* background-color: rgba(189, 167, 234, 1); */
  /* background-color: rgba(121, 94, 175, 1); */

  /* background-color: rgba(69, 49, 108, 1); */
  animation-name: button2Anim;
  animation-duration: 0.2s;
  background-color: #DD4088;
  /* color: rgba(255, 0, 126, 1); */
}

.button2:not(:hover) {
  animation-name: button2Anim2;
  animation-duration: 1.2s;
  background-color: black;
}

.button3:hover {
  /* background-color: rgba(189, 167, 234, 1); */
  /* background-color: rgba(121, 94, 175, 1); */

  /* background-color: rgba(69, 49, 108, 1); */
  animation-name: button3Anim;
  animation-duration: 0.2s;
  background-color: rgba(34, 29, 52, 0.85);;
  /* color: rgba(255, 0, 126, 1); */
}

.button3:not(:hover) {
  animation-name: button3Anim3;
  animation-duration: 1.2s;
  background-color: rgba(34, 29, 52, 0.45);;
}

body {
  margin: 0;
  padding: 0;
  background: black;
}

.headerText {
  /* position: relative;
  background-color: rebeccapurple; */
  height: 100px;
  /* font-family: 'SFProBold';
  font-size: 40px; */
}

.headerText2 {
  height: 200px;
  font-family: 'SFProBold';
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 0px
}

@keyframes appear {
  50% {
      text-shadow: 0 0 40px #cbc7c7;
      opacity: 0;
  }
  to {
      text-shadow: 0 0 20px #cbc7c7;
  }
}

h1 {
  font-size: 40px;
}

h2 { 
  font-size: 45px;
}

h4 {
  font-size: 16px;
}

h3 {
  font-size: 20px;
}


.headerText2 > h1 {
  background: linear-gradient(90deg, #181325 1.95%, #7957BF 111.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  animation: appear 1s;
}

.headerButtons {
  background: linear-gradient(90deg, #181325 1.95%, #7957BF 111.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradientColor {
  /* background: linear-gradient(90deg, #AD9FD3 0%, #7957BF 100%); */
  background: linear-gradient(90deg, #BDB2DB 0%, #DD4088 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradientColor2 {
  /* background: linear-gradient(90deg, #AD9FD3 0%, #7957BF 100%); */
  background: linear-gradient(90deg, #1D1A2D 0%, #DD4088 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.headerText2 > h6 {
  margin: 0px;
  font-family: 'SFProReg';
}

.circle {
  width: 200px;
  height: 200px;
  background: linear-gradient(90deg, #181325 1.95%, #7957BF 111.7%);
}

.first-section {
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  top: 0;
  left: 0;
  /* background-color: #9370DB; */
  /* z-index: -1; */

  display: flex;
  align-items: center;
  justify-content: center;
}

.call-to-action {
  /* background-color: aliceblue; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-content: center;
  margin-bottom: 40px;
}

.navbar {
  top: 0;
  left: 0;
  position: sticky;
  height: 100px;
  width: 100%;
  /* background-color: chartreuse; */
}

.action-phrase {

}

.action-description {

}

.action-description > h4 {
  margin: 0px;
}

.action-button {
  width: 100px;
  height: 50px;
  background-color: #cbc7c7;
  border-radius: 10px;
  margin-top: 50px;
}

main {
  width: 100%;
  height: 100%;
  /* top: calc(100vh - 100px); */
  position: relative;
}

.second-section {
  width: 100%;
  height: 300vh;
  background-color: transparent;
  /* background-image: url('./assets/back_second.png'); */

  /* background-color: white;
  background: radial-gradient(80.4% 80.4% at 50% 10%, #9370DB 0%, rgba(238, 40, 138, 0) 50%);
  filter: blur(100px); */

  display: flex;
  flex-direction: row;

  /* scroll-snap-type: y mandatory; */
  /* position: relative; */
  /* position: relative; */
}

@media screen and (max-width: 950px) {
  .second-section {
    height: auto;
    width: auto;
    padding: 80px 32px;
    flex-direction: column;
  }
}

.third-section {
  width: auto;
  height: auto;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 80px;
  padding-bottom: 80px;
  /* height: 100vh; */
  background-color: white;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
    /* border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px; */
  /* background-color: aqua; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* position: relative; */
}

.fourth-section {
  width: 100%;
  height: 100vh;
  background-color: blueviolet;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.left-side {
  height: 100%;
  /* flex: 0.4 1 auto; */
  width: 50vw;
  /* background-color: crimson; */
}

.right-side {
  height: 100%;
  /* flex: 1 1 auto; */
  width: 50vw;
  /* min-width: 600px; */
  /* background-color: gold; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column;
  justify-content: space-between; */
}

.product-template {
  /* width: 100%; */
  width: 100%;
  height: 33.333333%;


  /* background-color: chartreuse; */
  align-self: center;
  display: flex;

  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  flex-direction: column;
  /* scroll-snap-align: center; */
  /* float: right; */
  /* margin: 40px; */
}

.img-container {
  position: relative;
  /* background-color: #9370DB; */
  height: 85%;
  width: 100%;
  object-fit: cover;
  /* margin-left: 80px; */
}

.image1 {
  width: 30%;
  height: 30%;
  background-color: purple;
  /* scroll-snap-align: center; */
}

.image2 {
  width: 30%;
  height: 30%;
  background-color:green;
  /* scroll-snap-align: center; */
}

.image3 {
  width: 30%;
  height: 30%;
  background-color:orangered;
  /* scroll-snap-align: center; */
}

.buttons-div {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 100vh;
  /* background-color: lightsalmon; */
  display: flex;
  flex-direction: column;
  /* margin-left: 80px; */
}

.blur-second {
  width: 100%;
  height: 100%;
  background-color: white;
  background: radial-gradient(80.4% 80.4% at 50% 10%, #9370DB 0%, rgba(238, 40, 138, 0) 50%);
  filter: blur(1000px);
}

.cards-first-layer {
  display: flex;
  width: 100%;
  height: 400px;
  gap: 1.5em;
}

.cards-second-layer {
  display: flex;
  width: 100%;
  height: 180px;
  gap: 1.5em;
}

.left-card {
  /* flex: 0 1 auto; */
  width: 25%;
  background-color: #9370DB;
  /* min-width: 250px; */
}

.middle-card {
  /* flex: 0 1 auto; */
  /* flex: 0 0 auto; */
  width: 50%;
  background-color: #9370DB;
  overflow: hidden;
}
.right-card {
  /* flex: 0 1 auto; */
  width: 25%;
  background-color: #9370DB;
  /* min-width: 250px; */
}

.card {
  display: flex;
    padding: 2.5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    background-color: #f4f7ff;
    text-align: center;
}

/* .carousel {
  width: 100%;
  overflow: hidden;
}

.image-container {
  display: flex;
  gap: 10px;
  animation: slow-carousel 3s linear infinite;
}

.image-container img {
  width: 100%;
  height: auto;
  margin-right: 20px;
}

@keyframes slow-carousel {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
} */
.slider-container {
  display: flex;
  animation: slide 30s linear infinite;
}

.slider-images {
  display: flex;
  animation: slide-images 30s linear infinite;
  transform: translateX(-50%);
  margin-left: -60px;
}

.slider-images img {
  width: 120px;
  height: 120px;
  margin-right: 1em;
}

.slider-images img:last-child {
  margin-right: 0;
}

@keyframes slide {
  0% {
    transform: translateX(-960px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-images {
  0% {
    transform: translateX(50%);
    margin-left: 60px;
  }
  50% {
    transform: translateX(0);
    margin-left: 0;
  }
  100% {
    transform: translateX(-50%);
    margin-left: -60px;
  }
}

.slider-images img:first-child {
  margin-left: 0;
}

.slider-images img:last-child {
  margin-right: 0;
}

.feedback {
  /* width: 100%;
  height: 400px; */
  background: #f4f7ff;
  margin-top: 120px;
  border-radius: 35px;
}


@media screen and (min-width: 760px) {
  h1 {
    font-size: 40px;
  }

  h2 { 
    font-size: 30px;
  }

  h4 {
    font-size: 16px;
    /* font-size: calc(0.8vw + 1em); */
  }
  h3 {
    font-size: 20px;
  }
} 

@media screen and (min-width: 950px) {
  h1 {
    font-size: calc(4.375vw);
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: calc(1.75vw);
  }
}

@media screen and (max-width: 759px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px;
  }


  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 14px;
    /* font-size: calc(0.8vw + 1em); */
  }

  .second-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .third-section {
    padding-left: 20px;
    padding-right: 20px;
  }
} 

.canvas-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
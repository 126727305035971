.document {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 200px;
}

.document > h1 {
    color: white;
    font-family: 'SFProMedium';
    font-size: 30px;
}

.document > h2 {
    color: white;
    font-family: 'SFProMedium';
    font-size: 20px;
}

.document > p {
    color: white;
    font-family: 'SFProDisReg';
}

.document > ul {
    color: white;
    font-family: 'SFProDisReg';
}

@media screen and ( min-width: 1300px) {
    .document {
        padding: 100px 400px;
    }
}

@media screen and ( min-width: 1000px) and (max-width: 1300px) {
    .document {
        padding: 100px 250px;
    }
}

@media screen and ( max-width: 800px) and (min-width: 500px){
    .document {
        padding: 100px 100px;
    }
}

@media screen and ( max-width: 500px) {
    .document {
        padding: 100px 40px;
    }

    .document > h1 {
        font-size: 25px;
    }
}